#ResultsPage {
    background-color: #dec056;
    color: #242423;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

#results-head {
    font-style: italic;
    text-align: center;
}

#search-term {
    font-style: normal;
    font-weight: bold;
    font-size: 1em;
}

.album-card {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    padding: 10px;
}

.album-art {
    width: 80px;
    height: 80px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    cursor: pointer;
}

.album-info {
    margin-left: 10px;
}

.end {
    width: 50px;
    height: 50px;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
}

.end img {
    width: 30px;
    height: 20px;
    opacity: 0.5;
}

@media only screen and (min-width: 800px) {
    #results-list {
        display: grid;
        grid-template-columns: repeat(auto-fit, 250px);
        grid-gap: 30px;

        width: 90%;
        margin-left: auto;
        margin-right: auto;
    }

    #results-head {
        width: 90%;
        padding-left: 15px;
        margin-left: auto;
        margin-right: auto;
        font-size: 24px;
        text-align: left;
    }
    
    #search-term {
        font-style: normal;
        font-weight: bold;
        font-size: 1em;
    }
    

    .album-card {
        width: 250px;
        margin-left: auto;
        flex-direction: column;
        align-self: center;
        justify-self: center;
    }

    .album-art {
        width: 250px;
        height: 250px;
        margin-bottom: 10px;
    }

    .end {
        display: none;
    }
}