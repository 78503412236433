@import url('https://fonts.googleapis.com/css?family=Shrikhand&display=swap');

#header {
    height: 100px !important;
    flex-shrink: 0;

    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #dec056;
}

.title {
    font-family: 'Shrikhand', cursive;
    color: #242423;

    text-align: center;
}

#back {
  position: absolute;
  left: 20px;
}

#back img {
  margin-top: 5px;
  width: 30px;
  cursor: pointer;
}