#SearchPage {
    background-color: #dec056;

    display: flex;
    justify-content: center;
}

input[type=text] {
    width: 75vw;
    height: 30px;

    border: none;
    border-bottom: 3px solid #242423;
    border-radius: 0;

    background-color: transparent;
    color: #242423;

    font-size: 18px;
}

input[type=text]:focus {
    outline: none;
}

input[type=text]::placeholder {
    color: #424242;
}

input[type=submit] {
    display: none;
}

#search-icon {
    width: 30px;
    cursor: pointer;
}

#search-form {
    position: absolute;
    top: 20vh;
}

@media only screen and (min-width: 600px) {
    input[type=text] {
        max-width: 800px;
        height: 50px;
        font-size: 36px;
        border-bottom: 5px solid #242423;
    }

    #search-form {
        top: 30vh;
    }
}