* {
    margin: 0;
    padding: 0;
}

body {

}

#root {
    height: 100%;
}

.App {
    display: flex;
    flex-direction: column;
}