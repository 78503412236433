@import url(https://fonts.googleapis.com/css?family=Shrikhand&display=swap);
* {
    margin: 0;
    padding: 0;
}

body {

}

#root {
    height: 100%;
}

.App {
    display: flex;
    flex-direction: column;
}
#header {
    height: 100px !important;
    flex-shrink: 0;

    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #dec056;
}

.title {
    font-family: 'Shrikhand', cursive;
    color: #242423;

    text-align: center;
}

#back {
  position: absolute;
  left: 20px;
}

#back img {
  margin-top: 5px;
  width: 30px;
  cursor: pointer;
}
#Footer {
    background-color: #dec056;
    height: 50px;

    display: flex;
    flex-shrink: 0;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    color: #242423;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}
#SearchPage {
    background-color: #dec056;

    display: flex;
    justify-content: center;
}

input[type=text] {
    width: 75vw;
    height: 30px;

    border: none;
    border-bottom: 3px solid #242423;
    border-radius: 0;

    background-color: transparent;
    color: #242423;

    font-size: 18px;
}

input[type=text]:focus {
    outline: none;
}

input[type=text]::-webkit-input-placeholder {
    color: #424242;
}

input[type=text]::-moz-placeholder {
    color: #424242;
}

input[type=text]:-ms-input-placeholder {
    color: #424242;
}

input[type=text]::-ms-input-placeholder {
    color: #424242;
}

input[type=text]::placeholder {
    color: #424242;
}

input[type=submit] {
    display: none;
}

#search-icon {
    width: 30px;
    cursor: pointer;
}

#search-form {
    position: absolute;
    top: 20vh;
}

@media only screen and (min-width: 600px) {
    input[type=text] {
        max-width: 800px;
        height: 50px;
        font-size: 36px;
        border-bottom: 5px solid #242423;
    }

    #search-form {
        top: 30vh;
    }
}
#ResultsPage {
    background-color: #dec056;
    color: #242423;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

#results-head {
    font-style: italic;
    text-align: center;
}

#search-term {
    font-style: normal;
    font-weight: bold;
    font-size: 1em;
}

.album-card {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    padding: 10px;
}

.album-art {
    width: 80px;
    height: 80px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    cursor: pointer;
}

.album-info {
    margin-left: 10px;
}

.end {
    width: 50px;
    height: 50px;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
}

.end img {
    width: 30px;
    height: 20px;
    opacity: 0.5;
}

@media only screen and (min-width: 800px) {
    #results-list {
        display: grid;
        grid-template-columns: repeat(auto-fit, 250px);
        grid-gap: 30px;

        width: 90%;
        margin-left: auto;
        margin-right: auto;
    }

    #results-head {
        width: 90%;
        padding-left: 15px;
        margin-left: auto;
        margin-right: auto;
        font-size: 24px;
        text-align: left;
    }
    
    #search-term {
        font-style: normal;
        font-weight: bold;
        font-size: 1em;
    }
    

    .album-card {
        width: 250px;
        margin-left: auto;
        flex-direction: column;
        align-self: center;
        justify-self: center;
    }

    .album-art {
        width: 250px;
        height: 250px;
        margin-bottom: 10px;
    }

    .end {
        display: none;
    }
}
#AlbumPage {
    background-color: #dec056;
    display: flex;
    flex-direction: column;

    color: #242423;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

#album-info {
    flex: 0 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

#album-art {
    width: 150px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
    box-shadow: 0 4px 8px 0 rgba(20, 13, 13, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

#album-details {
    text-align: center;
}

#album-name {
    font-weight: bold;
    font-size: 28px;
}

#artist-name,
#date-num {
    font-size: 16px;
}

.byline {
    opacity: 0.5;
}

#view-controller {
    display: flex;
    justify-content: center;
    padding-left: 25px;
    padding-right: 25px;
    margin-top: 25px;
    margin-bottom: 15px;
    font-weight: bold;
}

#view-controller div{
    cursor: pointer;
    padding: 5px 5px 5px 5px;
    border: 1px solid #242423;
}

#track-button {
    border-radius: 5px 0 0 5px;
}

#chart-button {
    border-radius: 0 5px 5px 0;
}

.active {
    background-color: #242423;
    color: #dec056;;
}

@media only screen and (min-width: 800px) {
    #album-info {
        flex-direction: row;
    }

    #album-art {
        width: 300px;
        margin-right: 15px;
        margin-left: 30px;
    }

    #album-details {
        text-align: left;
    }

    #album-name {
        font-weight: bold;
        font-size: 36px;
    }
    
    #artist-name,
    #date-num {
        font-size: 24px;
    }

    #view-controller {
        width: 300px;
    }
}
#chart-container {
    width: 100%;
    height: 200px;

    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
}

#chart-controls {
    display: flex;
    flex-direction: column;
    margin-left: 30px;
}

.song-tooltip {
    background-color: rgba(200, 200, 200, 0.75);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    padding: 10px;
}

.valence-checkbox:checked {
    background-color: #3299bb;
}

.checkbox {
    margin-top: 5px;
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    background-color: #fafafa;
	border: 1px solid #cacece;
	box-shadow: 0 1px 2px rgba(0,0,0,0.05), inset 0px -15px 10px -12px rgba(0,0,0,0.05);
	padding: 9px;
	border-radius: 3px;
	display: inline-block;
	position: relative;
}

.checkbox:active, .checkbox:checked:active {
	box-shadow: 0 1px 2px rgba(0,0,0,0.05), inset 0px 1px 3px rgba(0,0,0,0.1);
}

.checkbox:checked {
	border: 1px solid #adb8c0;
	box-shadow: 0 1px 2px rgba(0,0,0,0.05), inset 0px -15px 10px -12px rgba(0,0,0,0.05), inset 15px 10px -12px rgba(255,255,255,0.1);
	color: #99a1a7;
}

#valence-checkbox:checked {
    background-color: #3299bb;
}

#danceability-checkbox:checked {
    background-color: #ff9900;
}

#tempo-checkbox:checked {
    background-color: #424242;
}

@media only screen and (min-width: 1000px) {
    #chart-container {
        width: 75%;
        height: 300px;
    }

    #chart-view {
        display: flex;
    }

    #chart-controls {
        width: 200px;
        justify-content: center;
    }
}

@media only screen and (min-width: 1400px) {
    #chart-container {
        width: 75%;
        height: 500px;
    }

    #chart-view {
        display: flex;
    }

    #chart-controls {
        width: 300px;
        justify-content: center;
    }
}
#list-view {
    display: flex;
    flex-direction: column;
}

#list-header {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    margin-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
    text-align: center;
    font-weight: bold;
}

.header-item {
    width: 90px;
    cursor: pointer;

    user-select: none; /* supported by Chrome and Opera */
   -webkit-user-select: none; /* Safari */
   -khtml-user-select: none; /* Konqueror HTML */
   -moz-user-select: none; /* Firefox */
   -ms-user-select: none; /* Internet Explorer/Edge */
}

#header-energy {
    display: none;
}

#header-danceability {
    width: 120px;
}

#header-valence {
    width: 110px;
}

#header-track {
    display: none;
}

.track {
    display: flex;
    flex-direction: column;

    color: #242423;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;

    padding: 10px;
}

.track-energy {
    display: none;
}

.stat-container {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    text-align: center;
    padding-left: 20px;
    padding-right: 20px;
}

.track-stat {
    width: 90px;
}

.stat-value {
    font-size: 20px;
}

.stat-label {
    font-weight: bold;
}

.color1 {
    background-color: rgba(255, 255, 255, 0.2);
}

@media only screen and (min-width: 800px) {
    .header-item {
        width: 150px !important;
        text-align: center;
    }

    #list-header {
        justify-content: left;
        margin-top: 10px;
        margin-bottom: 10px;
        padding-left: 10px;
        padding-right: 0px;
    }

    #header-track {
        display: block;
        min-width: 300px !important;
        flex: 1 1;
        text-align: left;
    }

    .track {
        flex-direction: row;
    }

    .stat-container {
        display: flex;
        justify-content: space-between;
        margin-top: 0px;
        text-align: center;
        padding-left: 0px;
        padding-right: 0px;
    }

    .track-name {
        min-width: 300px !important;
        flex: 1 1;
        font-size: 24px;
    }

    .track-stat {
        width: 150px;
    }
    
    .stat-value {
        font-size: 24px;
    }
}

@media only screen and (min-width: 1000px) {
    #list-header {
        padding-left: 50px;
    }

    .track {
        padding-left: 50px;
    }

    #header-energy {
        display: block;
    }

    .track-energy {
        display: block;
    }
}

@media only screen and (min-width: 1400px) {
    .stat-container {
        padding-right: 200px;
    }

    #list-header {
        padding-right: 200px;
    }
}
